import * as React from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapPin} from '@fortawesome/free-solid-svg-icons'

import {Link} from 'gatsby'
import Layout from '../../components/layout/layout'
import Adsense from "../../components/adsense/adsense";

const pageTitle = "Flying Sites 飛場推介"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
                                    <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto"/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <h1 className="pt-4 pb-4">
                            {pageTitle}
                        </h1>

                        <h4>南生圍大草地 Nam Sung Wai</h4>

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.21551502644!2d114.0291746502314!3d22.463346270389938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f0b8ca08c31d%3A0xde867191a708f360!2sRC%20Playground!5e1!3m2!1sen!2suk!4v1654531642595!5m2!1sen!2suk"
                            width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>

                        <ul className="text-muted list-inline">

                            <li><FontAwesomeIcon icon={faMapPin}/>&nbsp;
                                香港元朗南生圍路
                                Nam Sung Wai Road, Yuen Long, Hong Kong
                            </li>

                        </ul>


                        <div className="card mb-4">
                            <div className="card-body pb-0">
                                                        <a href="/info/sites/nam-sung-wai/"><img
                            className="img-fluid float-md-end ps-3 mb-3" alt="Blog Image"
                            style={{width: "300px"}}
                            src="https://agilehobby.s3.dualstack.ap-northeast-1.amazonaws.com/media/photos/site/thumbnail/869454c6-ef10-4957-a0e6-ca8ef061386a.jpg"/></a>

                                <p>南生圍的大草地是香港唯一一個免費適合的定翼機飛行的地方。放假及週末都會見到不少模友一展所長。</p>

                                <p>南生圍在十一月至四月的草會比較短，定翼機滑行起降沒有難度。夏天及雨季會多積水，草會比較長，機輪起降可以不用想。</p>

                                <p>近著河的一邊較多石，降落會不穩定，容易反機。</p>

                            </div>
                        </div>

                        <div className="embed-responsive embed-responsive-16by9">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/Kk-gQmRrdeI" title="YouTube video"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-md-7">
                                <h2 className="mb-3"> 起飛及進場路征 Special Instructions </h2>


                                <p>南生圍在十一月至四月的草會比較短，定翼機滑行起降沒有難度。夏天及雨季會積水，草比較長。</p>
                                <p>近著河的一邊較多石，降落會不穩定，容易反機。</p>
                                <h4 className="mb-3 mt-5">Safety Information</h4>
                                <p>請留意大草地是公眾地方，也有不小遊人，所以要務必要格外留神。</p>

                            </div>
                            <div className="col-12 col-md-5">
                                <img
                                    src="http://s3-ap-northeast-1.amazonaws.com/agilehobby/photos/articles/2014-04-23-Nam-Sung-Wai/nsw_takeoff.jpg"
                                    border="0" className="img-fluid"/>

                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className="col-12 col-md-7">
                                <h2 className="mb-3"> 交通 Getting There</h2>

                                <p>的士可以從 MTR 港鐵「元朗站」或「錦上路」站直入至「南生圍大草地」。車資大若 HK$60 元左右。</p>

                                <p>公共交通公具，只可以從 MTR 港鐵「元朗站」，經元朗舊墟路到山貝路，再坐街渡前往。全程大約要行 2 公里。</p>

                                <p>私家車在平日進入不會有麻煩。星期六日的話，因為單線雙程行車和多了不少「假日司機」，好大機會有交通堵塞的情況。</p>

                            </div>
                            <div className="col-12 col-md-5">
                                <img
                                    src="http://s3-ap-northeast-1.amazonaws.com/agilehobby/photos/articles/2014-04-23-Nam-Sung-Wai/instructions.jpg"
                                    border="0" className="img-fluid mb-5"/>

                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </Layout>

    )
}

export default IndexPage
